@import "fonts";
@import "bootstrap";
@import "helpers";
@import "~react-notifications-component/dist/theme.css";
@import "~react-responsive-carousel/lib/styles/carousel.min.css";
@import "slick";

@font-face {
  font-family: "MyriadPro";
  font-weight: bold;
  font-style: normal;
  src: url(../fonts/myriadPro/fonts/MyriadPro-Bold.otf) format("opentype");
}

@font-face {
  font-family: "MyriadPro";
  font-weight: bold;
  font-style: italic;
  src: url(../fonts/myriadPro/fonts/MyriadPro-BoldIt.otf) format("opentype");
}

@font-face {
  font-family: "MyriadPro";
  font-weight: 500;
  font-style: normal;
  src: url(../fonts/myriadPro/fonts/MyriadPro-Regular.otf) format("opentype");
}
@font-face {
  font-family: "MyriadPro";
  font-weight: 500;
  font-style: italic;
  src: url(../fonts/myriadPro/fonts/MyriadPro-It.otf) format("opentype");
}

@font-face {
  font-family: "MyriadPro";
  font-weight: 600;
  font-style: normal;
  src: url(../fonts/myriadPro/fonts/MyriadPro-Semibold.otf) format("opentype");
}

@font-face {
  font-family: "MyriadPro";
  font-weight: 600;
  font-style: italic;
  src: url(../fonts/myriadPro/fonts/MyriadPro-SemiboldIt.otf) format("opentype");
}

.slick-track {
  display: flex;
}

.slick-list {
  overflow: hidden;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "MyriadPro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  background-color: #eeeeee;
}
a {
  font-weight: 400;
}
h1,
h2 {
  font-size: 25px;
  color: #2a2a2a;
  margin-bottom: 30px;
  font-weight: bold;
}

h1.title {
  background: transparent;
  color: #252527;
  font-weight: 200;
  font-size: 2.4em;
  max-width: 240px;
  line-height: 1;
  margin: 0 0 1em;
  position: relative;

  &:before {
    content: "";
    width: 40px;
    height: 3px;
    position: absolute;
    background-color: #fcc06d;
    bottom: -13px;
  }
}

img {
  width: 100%;
}
textarea {
  background-color: transparent;
  border: 1px solid #9d9d9d;
  color: #252525;
  font-size: 13px;
  outline: none;
  padding: 10px 5px;
  &::-webkit-input-placeholder {
    /* Edge */
    color: #252525;
    font-weight: bold;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #252525;
    font-weight: bold;
  }

  &::placeholder {
    color: #252525;
    font-weight: bold;
  }
}
input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c8c8c8 !important;
  padding: 10px 5px;
  color: #252525;
  font-size: 13px;
  outline: none;
  &::-webkit-input-placeholder {
    /* Edge */
    color: #252525;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #252525;
  }

  &::placeholder {
    color: #252525;
  }
}
.btn {
  display: block;
  width: 100%;
  height: 43px;
  line-height: 43px;
  font-size: 14px;
  text-align: center;
  border-radius: 5px;
  transition: all 300ms linear;
  background: none;
  border: 1px solid transparent;
  color: #7d7d7d;
  font-weight: bold;
  white-space: nowrap;

  &:visited,
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }

  &.btn-white-yellow {
    border-color: #7d7d7d;
    color: #7d7d7d;
    background-color: #ffffff;

    &:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
      text-decoration: none;
    }
  }

  &.btn-default {
    border-color: #3d3d3d;
    color: #004c94;
    background-color: #ffffff;

    &.active,
    &:hover {
      color: #ffffff;
      border-color: #004c94;
      background-color: #004c94;
      text-decoration: none;
    }
    &.active:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
    }
  }

  &.btn-primary {
    color: #ffffff;
    border-color: #004c94;
    background-color: #004c94;

    &:hover {
      color: #004c94;
      border-color: #ffef00;
      background-color: #ffef00;
      text-decoration: none;
    }
    &:disabled,
    &.disabled {
      cursor: not-allowed;
      color: #dedede;
      border-color: #bbbbbb;
      background-color: #bbbbbb;
    }
  }

  &.btn-secondary {
    border-color: #dcdcdc;
    color: #707070;
    background-color: #dcdcdc;

    i {
      color: #004c94;
      margin-right: 5px;
    }

    &:hover {
      &,
      i {
        color: #ffffff;
      }
      border-color: #004c94;
      background-color: #004c94;
    }
  }
}
.LoadMoreButtom .spinner-border {
  color: #fff !important;
}
.img-post {
  display: block;
  margin: 10px 0;
  width: 100%;
}

// CSS FLOAT LABELS INPUT
/* Powered by: https://github.com/tonystar/float-label-css
 */

/* Re-usable mixins
 * https://github.com/tonystar/float-label-css/blob/master/scss/_mixins.scss
 */
@mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {
  label,
  > span {
    position: absolute;
    left: 0;
    top: -10px;
    cursor: text;
    font-size: 0%;
    opacity: 1;
    transition: all 0.2s;
    b {
      font-weight: bold;
    }
    @content;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 1;
    transition: all 0.2s;
  }
  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
    font-size: 13px;
    // opacity: .5;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 6px;
    @content;
  }
}
// LOADING CSS

// END LOADING CSS
/* Default styling
 * https://github.com/tonystar/float-label-css/blob/master/scss/float-label.scss
 */
.has-float-label .Form-Error {
  top: unset;
  bottom: -15px;
  color: red;
  font-size: 9px;
}
.has-float-label label b {
  font-size: 11px !important;
}
.has-float-label {
  @include float-label-container;

  @include float-label;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input,
  select {
    @include float-label-input;
    font-size: 13px;
    padding-top: 1em;
    margin-bottom: 2px;
    border: 0;
    border-radius: 0;
    width: 100%;

    @include float-label-scaled {
      top: 50%;
      transform: translateY(-50%);
    }
    &:focus {
      outline: none;
      border-color: rgba(0, 0, 0, 0.5);
    }
  }

  select {
    padding-right: 1em;
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
      no-repeat right 0.5em bottom 0.25em;
    background-size: 8px 10px;
    border-bottom: 1px solid #c8c8c8;
    padding: 16px 0 10px;
    margin-top: 4px;
  }
}

// CSS CUSTOM
.userButtons li {
  padding: 0;
}
.active .fa-plus-circle:before {
  content: "\f056";
}
.nav-color {
  background-color: #eeeeee;
  max-height: 67px;
  z-index: 1;
}
.nav-height {
  max-height: 67px;
}
.carousel.carousel-slider {
  max-width: 1245px;
  margin: 0 auto;
}
body .carousel.carousel-slider .control-arrow {
  top: unset;
  background-color: #038e83;
  opacity: 1;
  left: unset;
  width: 70px;
  height: 60px;
  display: block;
  z-index: 3;
}

body .carousel.carousel-slider .control-arrow.control-prev {
  right: 120px;
}

body .carousel.carousel-slider .control-arrow.control-next {
  right: 50px;
}

body .carousel.carousel-slider .control-arrow.control-next:before {
  content: "\f35a";
  font-family: "Font Awesome 5 Pro";
  border: 0;
}
body .carousel.carousel-slider .control-arrow.control-prev:before {
  content: "\f359";
  font-family: "Font Awesome 5 Pro";
  border: 0;
}

body .carousel.carousel-slider .control-arrow:hover {
  background-color: #038e83c6;
  opacity: 1;
  cursor: pointer;
}
.carousel .slide {
  background-color: #eee;
}
body .CourseBlock .carousel.carousel-slider .control-arrow {
  bottom: unset;
  top: 0;
  background-color: transparent;
  color: #038e83;
  font-size: 18px;
}

body .CourseBlock .carousel.carousel-slider .control-arrow:hover {
  opacity: 0.8;
}

.CourseBlock .carousel.carousel-slider {
  padding-top: 60px;
}

body .CourseBlock .carousel.carousel-slider .control-arrow.control-next {
  right: 0;
}
body .CourseBlock .carousel.carousel-slider .control-arrow.control-prev {
  right: 60px;
}

body .CourseBlock .carousel.carousel-slider .control-arrow.control-next:before {
  content: "\f054";
}

body .CourseBlock .carousel.carousel-slider .control-arrow.control-prev:before {
  content: "\f053";
}
a:hover {
  color: #fff;
}
.FaqBlock {
  background-color: #8591a5;

  //.faq-ButtonMore a {}
}
.openMenu {
  overflow: hidden;
  .nav-height {
    left: 0;
  }
}
.LoadMoreButtom {
  background-color: #038e83;
  align-items: center;
  border: none;
  font-weight: bold;
  color: #fff;
  padding: 10px;
  margin: 3em auto 5em;
  transition: ease all 300ms;
  &:hover {
    opacity: 0.8;
  }
}

.buttomModal {
  background-color: #038e83 !important;
  border: none;
  color: #fff !important;
  &:hover {
    opacity: 0.8;
  }
}

.buttomModalError {
  background-color: #a50500 !important;
  border: none;
  color: #fff !important;
  &:hover {
    opacity: 0.8;
  }
}

.CourseBlock {
  .slick-arrow {
    background-color: transparent;
    border: none;
    position: absolute;
    top: -50px;
    color: #eeeeee;
    width: 30px;
    height: 30px;
    outline: none;
  }
  .slick-prev {
    right: 50px;
    &:before {
      content: "\f053";
      display: block;
      font-size: 1.3em !important;
      width: 100%;
      height: 100%;
      color: #fcc06d;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
    }
  }

  .slick-next {
    right: 1px;
    &:before {
      content: "\f054";
      display: block;
      font-size: 1.3em !important;
      width: 100%;
      height: 100%;
      color: #fcc06d;
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
    }
  }
}

.authenticated-border {
  border: 1.2px solid #038e83 !important;
}

.container.slider-controls {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: -60px;

  button {
    color: #fff;
    background: #038e83;
    border: none;
    width: 70px;
    height: 60px;
    font-size: 30px;
    outline: none;
  }
}

.carousel.carousel-slider .control-arrow {
  display: none !important;
}

.height-auto {
  height: 100%;
}
